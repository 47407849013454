export const faviconSize = { height: 16, width: 16 };

export const themeBackgroundColor = "#1E2128";

export const themeColors = [
  "#7CBD5C",
  "#CF864A",
  "#C653DC",
  "#E5B35D",
  "#3B9EF2",
  "#25ABB7",
  "#E84A5F",
  "#9AA0B3",
];
